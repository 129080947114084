
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.loaderWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.15);
}

.statusText {
  padding: 16px;
  color: #ffffff;
  font-size: 40px;
}

.loader {
  display: inline-block;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #119bff;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
