
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
html,
body,
#root,
.App,
main {
  height: 100%;
}

body {
  margin: 0;
  font-family: $primary-font;
  background-image: url("../assets/images/background-blur.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

input {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

.bg--red {
  background-color: red;
}

.bg--green {
  background-color: green;
}

.bg--orange {
  background-color: orange;
}

.accountants-not-delete {
  min-width: 410px;
}
