
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.error {
  color: red;
}

.wrapper {
  display: flex;

  span {
    flex-grow: 1;
  }
}

.button {
  min-width: 87px;
}
