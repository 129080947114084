
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.wrapper {
  padding-left: 20px;

  form {
    margin-top: 5px;
  }
}

.title {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.content {
  padding-left: 20px;
}

.subtitle {
  margin-top: 10px;
  font-size: 20px;
}

.output {
  margin-top: 5px;
}
