
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.tooltipWrapperStyles {
  margin-left: 8px;
  cursor: pointer;
}

.tooltipIconStyles {
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}
