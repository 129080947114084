
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.pageWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: #fff;
}

.unauthorizedBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  .connectTitle {
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 42px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.68px;
    background: linear-gradient(
      92deg,
      #8dcce7 21.43%,
      #8bead1 50.63%,
      #e2da88 100%,
      #e4dd8f 117.04%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 642px;
  height: 520px;
  overflow: hidden;
  background: url("../../assets/images/contentWrapperBackground.svg") no-repeat center center;
  clip-path: polygon(40% 7%, 60% 7%, 69% 0, 100% 0, 100% 100%, 0 100%, 0 0, 31% 0);

  .contentHeader {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px 32px;

    .shortWallet {
      color: #119bff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.36px;
    }
  }

  .wingBottom,
  .wingTop {
    position: absolute;
    z-index: -1;
    width: 496px;
    height: 496px;
  }

  .wingBottom {
    bottom: 0;
    left: 0;
    background: url("../../assets/images/wingBottom.png");
    transform: translate(-50%, 55%);
  }

  .wingTop {
    top: 0;
    right: 0;
    background: url("../../assets/images/wingTop.png");
    transform: translate(50%, -50%);
  }

  .operationButtonsList {
    li {
      list-style: none;
    }

    .operationButton {
      margin-bottom: 16px;
    }
  }

  .mainError {
    color: #ff111f;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-align: center;
  }
}

.membersWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
}

.developmentMode {
  position: fixed;
  top: 30px;
  left: 10px;
  font-size: 25px;
  text-transform: uppercase;
}

.logoutButton {
  position: fixed;
  top: 30px;
  right: 10px;
}
