
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.wrapper {
  padding-left: 20px;
}

.title {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.inputWrapper {
  padding-left: 20px;
}

.list {
  margin-top: 10px;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 500px;
    margin-bottom: 3px;
    padding-left: 20px;
  }
}

.error {
  color: red;
}
